<template>
  <Scrollbar>
    <div class="w-full">
      <h1 class="m-4 school-title text-xl font-semibold text-blue-800">模块管理</h1>
      <div class="m-4">
        <el-form :inline="true" :model="formInline" class="demo-form-inline">
          <el-form-item label="模块名称">
            <el-input v-model="formInline.title" placeholder="模块名称"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handleLoad">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="m-4">
        <el-button type="primary" @click="$cloud.go('/pages/model/model_add')">创建模块</el-button>
      </div>
      <div class="m-4">
        <el-table
            :data="tableData"
            style="width: 100%">
          <el-table-column
              prop="id"
              label="编号"
              width="180">
          </el-table-column>
          <el-table-column
            prop="index"
            label="序号"
            width="180">
          </el-table-column>
          <el-table-column
              prop="title"
              label="名称"
              width="180">
          </el-table-column>
          <el-table-column
            prop="lockName"
            label="锁定状态"
            width="180">
          </el-table-column>
          <el-table-column
              prop="created_time"
              label="创建时间">
          </el-table-column>
          <el-table-column
              fixed="right"
              label="操作"
              width="220">
            <template slot-scope="{row}">
              <el-button v-if="row.lock !== 1" @click="handleClick(row)" type="text" size="small">编辑</el-button>
              <el-button v-if="row.lock !== 1" type="text" icon="el-icon-lock" @click="handleLock(row)">锁定</el-button>
              <el-button v-if="row.lock === 1" type="text" icon="el-icon-unlock" @click="handleUnLock(row)">解锁</el-button>
              <el-button v-if="row.lock !== 1" type="text" icon="el-icon-delete" @click="handleDelete(row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </Scrollbar>
</template>
<script type="text/javascript">
export default {
  name: "medal",
  data() {
    return {
      formInline: {
        title: "",
      },
      tableData: []
    };
  },
  mounted() {
    this.handleLoad();
  },
  methods: {
    handleLoad() {
      this.$cloud.post("block/list",this.formInline).then(data => {
        console.log("data", data)
        this.tableData = data;
      })
    },
    onSubmit() {
      console.log('submit!');
    },
    handleClick(row) {
      this.$cloud.go("/pages/model/model_edit", {id: row.id})
      console.log(row);
    },
    
    // 锁定
    handleLock(row){
      this.$confirm('确定要锁定该模型吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$cloud.post("block/lock", {id: row.id}).then(() => {
          this.$message.success('锁定成功')
          this.handleLoad()
        })
      })
    },
  
    //解锁
    handleUnLock(row){
      this.$prompt('请输入登录密码进行解锁', '解锁', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputType: 'password',
        inputPlaceholder: '请输入密码',
        inputErrorMessage: '输入不能为空',
        inputValidator: (value) => {       // 点击按钮时，对文本框里面的值进行验证
          if ( !value ) {
            return '输入不能为空';
          }
        }
      }).then(({value}) => {
        this.$cloud.post("block/unlock", {id: row.id,password: value}).then(() => {
          this.$message.success('解锁成功')
          this.handleLoad()
        })
      })
    },
  
    handleDelete(row){
      this.$confirm('确定要删除该模型吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$cloud.post("block/remove", {id: row.id}).then(() => {
          this.$message.success('删除成功！')
          this.handleLoad()
        })
      })
    },
  },
};
</script>
<style lang="scss"></style>